import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Seletor from "./screens/Seletor";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Seletor />
      </Route>
      <Route path="/app/" exact>
        <Seletor />
      </Route>
      <Route path="/apps/" exact>
        <Seletor />
      </Route>
    </Switch>
  );
};

export default Routes;
